import { HttpClient, IRequestOptions, IResponse } from "../../common/modules";
import { CustomError } from "../../common/modules/error.module";
import { HttpStatus } from "~/common/enums/const";
import { toast } from "react-toastify";
import Authenticate from "../authenticate/authenticate";
import history from "~/common/utils/browser-history";

// import history from "~/common/utils/browser-history";

export interface IApiResponse {
    code: number,
    data: any,
    message: string
}

class ApiService {
    history: any;
    private baseUrl: string;
    options: IRequestOptions
    private httpClient: HttpClient

    getHttpClient() {
        this.options.headers["Authorization"] = "Bearer " + Authenticate.getUserSessionToken();
        let httpClient = new HttpClient(this.baseUrl, this.options);
        return httpClient;
    }
    constructor(baseurl: string, options: IRequestOptions) {
        //this.getHttpClient() = new HttpClient(baseurl, options);
        this.baseUrl = baseurl;
        this.options = options;
    }
    get<T = IApiResponse>(url: string, body?: any): Promise<T> {
        if (body) {
            url += "?" + Object.keys(body).map(function (k) {
                return encodeURIComponent(k) + '=' + encodeURIComponent(body[k])
            }).join('&')
        }
        return new Promise((resolve, reject) => {
            this.getHttpClient().get<T>(url, body).toPromise().then((data: IResponse<T>) => {
                if (data.status > 300) {
                    reject(new CustomError['NotAcceptable']())
                } else {
                    resolve(data.response)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    }

    post<T = IApiResponse>(url: string, body?: any): Promise<T> {
        return new Promise((resolve, reject) => {
            this.getHttpClient().post<T>(url, body).toPromise().then((data: IResponse<T>) => {
                if (data.status > 300) {
                    reject(new CustomError['NotAcceptable']())
                } else {
                    resolve(data.response)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    }
    put<T = IApiResponse>(url: string, body?: any): Promise<T> {
        return new Promise((resolve, reject) => {
            this.getHttpClient().put<T>(url, body).toPromise().then((data: IResponse<T>) => {
                if (data.status > 300) {
                    reject(new CustomError['NotAcceptable']())
                } else {
                    resolve(data.response)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    }
    patch<T = IApiResponse>(url: string, body?: any): Promise<T> {
        return new Promise((resolve, reject) => {
            this.getHttpClient().patch<T>(url, body).toPromise().then((data: IResponse<T>) => {
                if (data.status > 300) {
                    reject(new CustomError['NotAcceptable']())
                } else {
                    resolve(data.response)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    }

    delete<T = IApiResponse>(url: string, body?: any): Promise<T> {
        return new Promise((resolve, reject) => {
            this.getHttpClient().delete<T>(url, body).toPromise().then((data: IResponse<T>) => {
                if (data.status > 300) {
                    reject(new CustomError['NotAcceptable']())
                } else {
                    resolve(data.response)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    }
    getJSON<T>(url: string, headers?: Object): Promise<T> {
        return this.getHttpClient().getJSON<T>(url, headers).toPromise();
    }


    generalProcessDataResponse(data: IApiResponse) {
        if (data.code === HttpStatus.Forbidden) {
            toast.error(data.message);
            return false;
        }

        if (data.code === HttpStatus.Unauthorization) {
            //toast.error(data.message);
            Authenticate.logout();
            history.push("/login");
            //throw new Error("Session het han")
            return false;
        }
        if (data.code === HttpStatus.InternalServerError) {
            toast.error(data.message);
            return false;
        }
        if (data.code === HttpStatus.BadRequest) {
            toast.error(data.message);
            return false;
        }

        return true;
    }

}
// let port = 8000;
// if (window.location.host.indexOf("aeon") > 0) {

// }
// if (window.location.host.indexOf("demo.crm") > 0) {
//     port = 8010;
// }

// let domainName ="https://demo.crm.wifiplatform.vn/api/";
// let domainName ="https://aeon.wifiplatform.vn/api/";
// let domainName = (window.location.protocol + "//" + window.location.host + "/api/");
let domainName = "";
if(window.location.host.indexOf('localhost') >= 0 ) domainName =  "http://localhost:8010/api/"; 
else if(window.location.host.indexOf('demo.crm') >= 0) domainName = "https://demo.crm.wifiplatform.vn/api/"; 
else if(window.location.host.indexOf('aeon.crm') >= 0 || window.location.host.indexOf('aeon.wifiplatform') >= 0 ) domainName = "https://aeon.wifiplatform.vn/api/";

export { domainName }

export const apiService = new ApiService(domainName, {
    timeout: 180000,
    crossDomain: false,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': "*",
        'x-api-key': 'e8839f6a-0c6e-11ea-8d71-362b9e155667'
    }
})

// export const apiService = new ApiService('http://localhost:8002/api/', {
//     timeout: 30000,
//     crossDomain: false,
//     headers: {
//         'Content-Type': 'application/json',
//         'Accept': 'application/json',
//         'Access-Control-Allow-Origin': "*",
//         'x-api-key': 'e8839f6a-0c6e-11ea-8d71-362b9e155667'
//     }
// })

export const BaseApiUrl = domainName;
export const apiMapService = new ApiService('https://maps.googleapis.com/maps/api/', {
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': "*"
    }
})