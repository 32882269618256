import { Button, Card, Col, DatePicker, Input, Modal, Row, Select, Spin, Table, Tag } from 'antd';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import Column from 'antd/lib/table/Column';
import moment from 'moment';
import React from 'react';
import Authenticate from '~/services/authenticate/authenticate';
import { Campaign } from '~/services/campaign/campaign.model';
import campaignService from '~/services/campaign/campaign.service';
import { Profile } from '~/services/profile/profile.model';
import profileService from '~/services/profile/profile.service';
import { User } from '~/services/user/user.model';
import voucherTypeService from '~/services/voucher-type/voucher-type.service';
import ProfileForm from './profile.modal';
import { Award } from '~/services/models/award';
import { toast } from 'react-toastify';
import ExportOptionForm from './export-option.modal';
import { FormFieldSetting } from '~/services/form-field/formfield-setting.model';
import { numberWithCommas } from '~/common/utils/helper';
import { OrderImage } from '~/services/profile/order-image.model';

const { RangePicker } = DatePicker;
const { Option } = Select;
interface IActiveBtnProps {
    is_deleted: any;
    activeFn: any,
    inActiveFn: any,
    itemId: number
}

export default class ProfilePage extends React.Component<{ history: any }, {}> {
    formRef: any;
    state = {
        exportModal: { isModalShow: false },
        currentUser: Authenticate.getUserSession(),
        quickRangeSelect: null,
        enableChangeMall: true,
        dateForm: moment().subtract(7, 'day'),
        dateTo: moment(),
        tableLoading: false,
        profiles: [],
        total: 0,
        totalAwards: 0,
        totalVouchers: 0,
        unitCreatedDate: [],
        formInfo: {
            item: new Profile(),
            isModalShow: false
        },
        mall: Authenticate.getUserSession().malls,
        campaignSelected: new Campaign(),
        voucherTypes: [],
        campaign: {
            data: [], total: 0, nameFiltered: '',
            fetching: false,
            lastTypingTime: new Date().getTime(),
        },
        filteredInfo: {
            name: "",
            awardId: 0,
            campaignId: 0,
            mallId: 100,
            search: "",
            pageIndex: 1,
            createdDate: [moment().subtract(7, 'day'), moment()]
        }
    }
    searchInput: Input;

    componentDidMount() {
        this.loadCampaignList();
        this.loadVoucherTypeList();
        let { mall, filteredInfo } = this.state;
        if (Authenticate.isAdmin()) {
            mall = [{ id: 0, name: "Tất cả chi nhánh" }, ...mall];
        }
        this.setState({ mall, filteredInfo: { ...filteredInfo, mallId: mall[0].id } })
    }

    handleClearSearch() {
        this.setState({
            filteredInfo: {
                searchedColumn: '',
                name: '',
                campaignId: 0,
                mallId: 0,
                awardId: 0,
                createdDate: [moment().subtract(7, 'day'), moment()]
            }
        });
    }

    loadProfile() {
        this.setState({ tableLoading: true });
        let profiles = [];
        let total = 0;
        let totalAwards = 0;
        let totalVouchers = 0;
        let unitCreatedDate = [];
        profileService.getProfiles(this.state.filteredInfo).then(data => {
            if (!data.list) return;
            profiles = data.list.map(item => { return { ...item, key: item.id } });
            total = data.total;
            totalAwards = data.totalAwards;
            totalVouchers = data.totalVouchers;
            unitCreatedDate = data.unitCreatedDate || [];
        })
            .finally(() => {
                this.setState({ ...this.state, tableLoading: false, profiles, total, totalAwards, totalVouchers, unitCreatedDate });
            })
    }

    showExportOptionModal() {
        if (!this.state.filteredInfo.campaignId) {
            toast.error("Vui lòng chọn chương trình");
            return;
        }
        this.setState({ exportModal: { isModalShow: true } })
    }

    exportExcel(exportFieldSelect: FormFieldSetting[]) {
        let fields = {};
        exportFieldSelect.forEach(f => { fields[f.code] = f.isExport })
        this.setState({ ...this.state, tableLoading: true, exportModal: { isModalShow: false } });
        let profiles = [];
        let total = 0;
        profileService.exportProfile({ ...this.state.filteredInfo, fields }).then()
            .finally(() => {
                this.setState({ ...this.state, tableLoading: false });
            })
    }

    openModalDetail(item) {
        item.vouchersEdit = item.vouchers.split(",");
        item.billNumbersEdit = item.billNumbers.split(",");
        this.setState({
            formInfo: {
                item: item,
                isModalShow: true

            }
        })
    }

    createItem() {
        this.setState({
            formInfo: {
                isModalShow: true,
                item: new User()
            }
        })
    }

    loadVoucherTypeList() {
        let datas = [];
        let { voucherTypes } = this.state;
        voucherTypeService.getData().then(data => {
            this.setState({ ...this.state.voucherTypes, voucherTypes: data });
        })
            .catch(error => {
                console.log(error);
            })
    }

    loadCampaignList() {
        let datas = [];
        let { campaign } = this.state;
        campaignService.getData({ pageIndex: 1, pageSize: 15, name: this.state.campaign.nameFiltered }).then(data => {
            campaign.data = data.list;
        })
            .catch(error => {
                console.log(error);
            }).finally(() => {
                this.setState({});
            })
    }

    onAwardSelected(value) {
        let { filteredInfo } = this.state;
        filteredInfo.awardId = value;
    }

    onSearchTextChanged(event) {
        let { filteredInfo } = this.state;
        let search = event.target.value;
        this.setState({ ...this.state, filteredInfo: { ...filteredInfo, search } })
        console.log(this.state.filteredInfo.search);


    }

    async onCampaignSelected(value) {
        if (!value) value = 0;
        let { filteredInfo, campaign, mall } = this.state;
        filteredInfo.campaignId = value;
        let cp = !value ? new Campaign() : await campaignService.getDetail(value);
        this.setState({ mall, campaignSelected: cp, dateForm: moment(cp.fromDate), dateTo: moment(cp.toDate) });
        filteredInfo.createdDate = [moment(cp.fromDate), moment(cp.toDate)];
        //quickRangeSelect
        let quickRangeSelect = {};
        quickRangeSelect["Toàn chương trình"] = [moment(cp.fromDate).startOf("day"), moment(cp.toDate).endOf("day")]
        for (let i = 0; i < 7; i++) {
            let fromDate = new Date(cp.fromDate);
            let toDate = new Date(cp.toDate);
            if (fromDate && fromDate.getTime() + 86400000 * i < toDate.getTime()) {
                quickRangeSelect[moment(fromDate).add(i, "day").format("DD/MM")]
                    = [moment(fromDate).add(i, "day").startOf("day"), moment(fromDate).add(i, "day").endOf("day")]
            }
        }
        this.setState({ quickRangeSelect });

    }
    onMallSelected(value) {
        let { filteredInfo } = this.state;
        filteredInfo.mallId = value;
        this.setState({});

    }
    onDateFilterChange(dateRange: RangePickerValue) {
        let { filteredInfo } = this.state;
        filteredInfo.createdDate = [dateRange[0], dateRange[1]];
        this.setState({ dateForm: dateRange[0], dateTo: dateRange[1] });
    }

    saveFormRef = (formRef: any) => {
        this.formRef = formRef;
    };

    onChange(pagination, filters, sorter, extra) {
        let { filteredInfo } = this.state;
        filteredInfo.pageIndex = pagination.current;
        this.setState({}, () => {
            this.loadProfile();
        })
    }

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    updateUserInList(profile: Profile) {
        let profiles = this.state.profiles.map(item => {
            if (item.id === profile.id) return profile;
            return item;
        })
        profiles = profiles.map(item => { item.key = item.id; return item; });
        this.setState({ profiles });
    }

    handleChangeCampaignName(value) {
        this.setState({ campaign: { nameFiltered: value } })
    }

    previewSignatureModal(urlImage) {
        if (urlImage) {
            Modal.success({
                title: "Chữ ký",
                maskClosable: true,
                content: <img style={{ maxWidth: "100%" }} src={urlImage} />,
            });
        }
    }

    previewOrderImage(urlImage) {
        if (urlImage) {
            Modal.success({
                title: "Hình ảnh hóa đơn",
                width: "1000px",
                maskClosable: true,
                content: <img style={{ maxWidth: "100%" }} src={urlImage} />,
            });
        }
    }

    render() {
        const { state } = this;
        let filteredInfo = this.state.filteredInfo;
        const campaigns = this.state.campaign.data || [];
        let awards = this.state.campaignSelected.awards;
        if (awards && awards.length > 0) {
            awards = [{ id: 0, name: "Tất cả" } as Award, ...awards]
        }
        const dateFormat = 'DD/MM/YYYY';
        let dateUnitAwards = this.state.unitCreatedDate.map(item =>
            <Tag color="blue" style={{ fontSize: "12px", fontWeight: "bold", padding: "5px" }}>{item.date}</Tag>
        )
        let dynamicFieldShow = this.state.campaignSelected.inputFields.filter(f => f.code == "dynamic" && f.isShow).length > 0;
        dynamicFieldShow = false;
        return (
            <div className="content profile">
                <div className="row" style={{ marginBottom: "-15px" }}> <h2 style={{ textAlign: "center", textTransform: "uppercase" }}>BÁO CÁO CHƯƠNG TRÌNH</h2> </div>

                <Card
                    title="Thống kê"
                    bordered={false}
                    style={{ width: "100%" }}
                >
                    <Row className="row">
                        <Col xs={8} md={8} xl={3} style={{
                            textAlign: "right", paddingTop: "5px", paddingRight: "10px"
                        }}> Chọn chương trình: </Col>
                        <Col xs={16} md={14} xl={5} >
                            <Select
                                mode="default"
                                showSearch
                                autoClearSearchValue={true}
                                //value={this.state.campaign.data}
                                placeholder="Chọn chương trình"
                                allowClear
                                notFoundContent={this.state.campaign.fetching ? <Spin size="small" /> : null}
                                filterOption={false}
                                onSearch={this.handleChangeCampaignName.bind(this)}
                                onChange={this.onCampaignSelected.bind(this)}
                                onInputKeyDown={(e) => {
                                    if (e.keyCode == 13)
                                        this.loadCampaignList();
                                }}
                                style={{ width: '100%' }}
                            >
                                {campaigns.map(d => (
                                    <Option key={d.id}>{d.name}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col xs={8} md={8} xl={3} style={{
                            textAlign: "right", paddingTop: "5px", paddingRight: "10px"
                        }}> Chọn chi nhánh: </Col>
                        <Col xs={16} md={14} xl={5} >
                            <Select
                                mode="default"
                                allowClear
                                disabled={!this.state.enableChangeMall}
                                value={this.state.filteredInfo.mallId}
                                //value={this.state.campaign.data}
                                onDeselect={() => { this.setState({ ...this.state, filteredInfo: { mallId: 0 } }) }}
                                placeholder="Chọn chi nhánh"
                                notFoundContent={this.state.campaign.fetching ? <Spin size="small" /> : null}
                                filterOption={false}
                                onSelect={this.onMallSelected.bind(this)}
                                style={{ width: '100%' }}
                            >
                                {this.state.mall && this.state.mall.map(d => (
                                    <Option key={d.id} value={d.id}>{d.name}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col xs={8} md={8} xl={3} style={{
                            textAlign: "right", paddingTop: "5px", paddingRight: "10px"
                        }}> Thời gian tạo: </Col>
                        <Col xs={16} md={14} xl={5} >
                            <RangePicker onChange={this.onDateFilterChange.bind(this)}
                                value={[this.state.dateForm, this.state.dateTo]} format={dateFormat}
                                ranges={this.state.quickRangeSelect} />
                        </Col>

                        <Col xs={8} md={8} xl={3} style={{
                            textAlign: "right", paddingTop: "5px", paddingRight: "10px"
                        }}> Giải thưởng: </Col>
                        <Col xs={16} md={14} xl={5} >
                            <Select
                                mode="default"

                                //value={this.state.campaign.data}
                                placeholder="Chọn giải thưởng"
                                allowClear
                                //notFoundContent={this.state.campaign.fetching ? <Spin size="small" /> : null}
                                filterOption={false}
                                onChange={this.onAwardSelected.bind(this)}
                                style={{ width: '100%' }}
                            >
                                {awards.map(award => (
                                    <Option key={award.id}>{award.name}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col xs={8} md={8} xl={3} style={{
                            textAlign: "right", paddingTop: "5px", paddingRight: "10px"
                        }}> Tìm kiếm: </Col>
                        <Col xs={16} md={14} xl={5}  >
                            <Input
                                placeholder="Tên, Số đt, Mã thành viên, Mã trúng thưởng"
                                allowClear
                                value={this.state.filteredInfo.search}
                                onChange={this.onSearchTextChanged.bind(this)}
                                style={{ width: '100%' }}
                            />
                        </Col>

                        <Col xs={24} md={22} xl={8} style={{
                            textAlign: "center", paddingTop: "5px", paddingRight: "10px"
                        }}>
                            <Button onClick={() => {
                                let { filteredInfo } = this.state;
                                filteredInfo.pageIndex = 1;
                                this.loadProfile();
                            }} type="primary" loading={this.state.tableLoading} icon="search"> Tìm kiếm </Button>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: "5px" }} >
                        <Tag color="#2db7f5" style={{ fontSize: "15px", fontWeight: "bold", padding: "7px" }}>Tổng khách hàng: {this.state.total}</Tag>
                        {/* <Tag color="#40A9FF" style={{ fontSize: "15px", fontWeight: "bold", padding: "7px" }}>Tổng giải thưởng: {this.state.totalAwards}</Tag> */}
                        <Tag color="#87d068" style={{ fontSize: "15px", fontWeight: "bold", padding: "7px" }}>Tổng giải thưởng: {this.state.totalVouchers}</Tag>
                        <Button onClick={this.showExportOptionModal.bind(this)} type="primary" style={{ float: "right" }} loading={this.state.tableLoading} icon="export"> Xuất excel </Button>
                    </Row>
                    <Row style={{ marginTop: "5px" }} >
                        {dateUnitAwards}
                    </Row>
                </Card>
                <div style={{ overflowX: "scroll" }}>
                    <Table
                        rowKey="id"
                        showHeader={true}
                        size="small"
                        style={{ minWidth: 2000 }}
                        onChange={this.onChange.bind(this)}
                        loading={this.state.tableLoading}
                        //expandedRowRender={record => <p style={{ margin: 0 }}>{record.description}</p>}
                        dataSource={this.state.profiles}
                        pagination={{ total: this.state.total, position: 'both' }}
                    // scroll={{ y: 450 }}
                    >
                        {/* <Column title="ID" width={"50px"} dataIndex="id" key="id" sortOrder="descend" defaultSortOrder="descend" ></Column> */}
                        <Column width="150px" title="Ngày thêm" key={"createdDate"} render={(text, record) => {
                            return moment(record["createdDate"]).format("DD-MM-YYYY HH:mm");
                        }}></Column>
                        <Column width="150px" title="Chi nhánh" render={(text, record: any) => {
                            return record.mall && record.mall.name || "";
                        }}></Column>
                        <Column width="150px" title="Tên khách hàng" render={(text, record: any) => {
                            let nameElement = <span>{record.name} {record.note ? <span style={{ "color": "red", fontSize: "11px" }}>(*)</span> : ''} </span>
                            return nameElement;
                        }} key="name"></Column>
                        <Column width="160px" title="Giải thưởng" dataIndex="awardReceiveds" key="awardReceiveds" render={(text, record) => {
                            let result = [];
                            record["awardReceiveds"].forEach(item => {
                                try {
                                    if (!item.award || !item.award.name || !result[item.award.name]) result[item.award.name] = [];
                                    result[item.award.name].push(item.serial)
                                } catch (e) {

                                }

                            });
                            return Object.keys(result).map(itemName => {
                                let listVoucher = result[itemName].join(", ")
                                return (<Tag style={{ whiteSpace: "normal" }} key={itemName + listVoucher}>{itemName}: {listVoucher}</Tag>)
                            })
                        }} ></Column>
                        {/* <Column title="Serial voucher" dataIndex="vouchers" key="vouchers" render={(text, record) => {
                            return text.replace(/,/g, ", ");
                        }} ></Column> */}
                        <Column title="Giá trị hóa đơn" dataIndex="priceRanges" key="priceRanges"
                            render={(text, record) => {
                                return (text || "").replace(/,/g, ", ");
                            }}></Column>
                        <Column title="Chi tiết g.trị hóa đơn" dataIndex="orderPrices" key="orderPrices"
                            render={(text: string, record) => {
                                return text && text.split(',').map(txt => {
                                    return numberWithCommas(txt);
                                }).join(", ");
                            }}></Column>
                        <Column title="Số hóa đơn" dataIndex="billNumbers" key="billNumbers" render={(text, record) => {
                            if (record["orders"] && record["orders"].length > 0) {

                                return record["orders"].map((element, i) => {
                                    return (<Tag style={{ whiteSpace: "normal" }} key={element.orderNumber + i}>{element.orderNumber}: {numberWithCommas(element.price)}</Tag>)
                                })

                            } else return record['billNumbers'];
                            // return text ? text.replace(/,/g, ", "): '';
                        }}></Column>
                        <Column width="150px" title="Tổng hóa đơn" dataIndex="orderTotalPrice" key="orderTotalPrice" render={(text, record) => {
                            return text && numberWithCommas(text);
                        }}></Column>

                        <Column title="Hình ảnh hóa đơn" dataIndex="orderImages" key="orderImages"
                            render={(text: string, record) => {
                                if (!record["orderImages"]) return "";
                                return record["orderImages"] && (record["orderImages"] as OrderImage[]).map(orderImage =>
                                    <img style={{ cursor: "pointer", maxHeight: "30px", border: "solid 1px #CCC", borderRadius: "2px", display: "inline", margin: "1px" }} src={orderImage.path} onClick={() => this.previewOrderImage(orderImage.path)} />
                                )
                            }}></Column>
                        <Column title="Mã thẻ thành viên" dataIndex="memberCode" key="memberCode"></Column>
                        <Column width="150px" title="Số điện thoại" dataIndex="phone" key="phone"></Column>
                        <Column width="150px" title="Số CMND/CCCD" dataIndex="passport" key="passport"></Column>
                        <Column width="150px" title="Ghi  chú" dataIndex="note" key="note"></Column>
                        {dynamicFieldShow?  <Column width="150px" title="Trường linh động" dataIndex="dynamic" key="dynamic"></Column> : ''}
                        {this.state.campaignSelected.isGame ?
                            <Column width="150px" title="Đã nhận quà" dataIndex="gameAwardReceived" key="gameAwardReceived"
                                render={(text, record: any) => (record['gameAwardReceived'] ? 'True' : 'False')}
                            ></Column> : ''}
                        <Column title="Chữ ký" width="60px"
                            onCell={(record, index) => {
                                return {
                                    onClick: () => this.previewSignatureModal(record["signature"])
                                }
                            }} render={(text, record) => (
                                <img style={{ cursor: "pointer", maxHeight: "50px" }} src={record["signature"] + "?rand=" + Math.random()} />)
                            }></Column>

                    </Table>
                </div>
                <ProfileForm
                    // wrappedComponentRef={this.saveFormRef}
                    ref={this.saveFormRef}
                    campaign={this.state.campaignSelected}
                    visible={this.state.formInfo.isModalShow}
                    onCancel={() =>
                        this.setState({
                            formInfo: { item: new Profile(), isModalShow: false }
                        })
                    }
                    voucherTypes={this.state.voucherTypes}
                    onCreate={() => { this.setState({ formInfo: { item: new Profile(), isModalShow: false } }); this.loadProfile() }}
                    profile={this.state.formInfo.item}
                ></ProfileForm>
                <ExportOptionForm
                    key={"exportModal"}
                    visible={this.state.exportModal.isModalShow}
                    campaign={this.state.campaignSelected}
                    onCancel={() =>
                        this.setState({
                            exportModal: { isModalShow: false }
                        })
                    }
                    onExport={(exportFieldSelect: FormFieldSetting[]) => { this.exportExcel(exportFieldSelect) }}
                ></ExportOptionForm>
            </div >

        );
    }
}
