import { apiService } from "../api/api.service";

class SystemInfoService {
    async getData() {
        let result = await apiService.get("system-info/disk-usage");
        if (!apiService.generalProcessDataResponse(result)) return {};
        return result.data;
    }

}
export default new SystemInfoService();