import { Icon, Layout, Menu, Modal, Card, Input, Progress } from 'antd';
import React, { Component, Fragment } from 'react';
import { Link, Route } from 'react-router-dom';
import appRouter from '~/routers/app.router';
import Authenticate from '~/services/authenticate/authenticate';
import './style.css';
import ChangePassForm from './change-pass.modal';
import { ROLES } from '~/common/enums/const';
import systemInfoService from '~/services/system-info/system-info.service';
import { domainName } from '~/services/api/api.service';
import { DiskUsageWarningModal } from './disk-usage-warning.modal';

const { Header, Content, Footer, Sider } = Layout;

const { SubMenu } = Menu;
const fixRootKey = (value: number) => {
    if (value < 10) {
        return '00' + value;
    }
    if (value < 100) {
        return '0' + value;
    }
    return value + '';
}
const renderLinkRouter = () => {
    const elements = [];
    appRouter.map((item: any, index) => {

        const { icon, title, path, roles } = item;
        let isAllow = false;
        if (!roles) { isAllow = true; }
        else {
            roles.forEach(role => {
                isAllow = Authenticate.getUserSession() && Authenticate.getUserSession().roles &&
                    Authenticate.getUserSession().roles.findIndex(r => r.name == role) >= 0 || isAllow;
            })
        }
        if (item.subs) {
            const subElements = item.subs.map((childItem, idx) => {
                const { icon, title } = childItem
                const pathChild = path + childItem.path
                const key = fixRootKey(index) + '-' + idx;
                return (
                    <Menu.Item key={key} >
                        <Icon type={icon.type} theme={icon.theme} />
                        <span>{title}</span>
                        <Link to={pathChild} />
                    </Menu.Item>
                )
            })
            const subKey = fixRootKey(index);
            const elm = (<SubMenu
                key={subKey}
                title={
                    <span>
                        <Icon type={icon && icon.type ? icon.type : undefined} />
                        <span>{title}</span>
                    </span>
                }
            >
                {subElements}
            </SubMenu>);
            elements.push(elm)
        } else {
            const key = fixRootKey(index);
            if (isAllow) {
                elements.push(
                    <Menu.Item key={key} >
                        <Icon type={icon.type} theme={icon.theme} />
                        <span>{title}</span>
                        <Link to={path} />
                    </Menu.Item>
                )
            }

        }
    });

    return elements;
}
const switchRoutes = (

    <Fragment>
        {appRouter.map((item: any, index) => {
            const { path, component } = item;
            if (item.subs) {
                return item.subs.map((childItem, idx) => {
                    const { component } = childItem

                    const pathChild = path + childItem.path
                    const key = fixRootKey(index) + '-' + idx;
                    return (
                        <Route exact key={key} path={pathChild} component={component} />
                    )
                })
            }
            const key = fixRootKey(index);
            return (
                <Route exact path={path} key={key} component={component} />
            );
        })}
    </Fragment>
);
const routerLinks = renderLinkRouter();

export default class AppLayout extends Component<{ history: any }, any> {
    state = {
        collapsed: false,
        userMenuCollapsed: true,
        current: 'mail',
        openChangePassModel: false,
        severStorageInfo: {
            diskUsage: 35 * 1024,
            systemDiskAvailable: 100,
            serviceDiskUsage: 40 * 1024
        },
        userName: Authenticate.getUserSession().fullname,
        openDiskUsageWarningModal: false,
    };

    componentDidMount() {
        this.loadSystemStorageInfo();
    }
    loadSystemStorageInfo() {
        systemInfoService.getData().then((result: any) => {
            this.setState({ 
                severStorageInfo: result,
                openDiskUsageWarningModal: Number(result.systemDiskAvailable) < 10
            });
        });
    }

    onCollapse = (collapsed) => {
        this.setState({ collapsed });
    }

    onUserMenuCollapse = (userMenuCollapsed) => {
        this.setState({ userMenuCollapsed });
    }
    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
            current: 'mail'
        });
    }
    logout = () => {
        Authenticate.logout();
        this.props.history.push("/login");
    }
    render() {
        let percentUsage = (this.state.severStorageInfo.diskUsage || 0) / this.state.severStorageInfo.serviceDiskUsage * 100;
        let diskUsageValueGb = (this.state.severStorageInfo.diskUsage / 1024).toFixed(2);
        let totalDiskUsageGb = (this.state.severStorageInfo.serviceDiskUsage / 1024).toFixed(2);
        const showChangePass = Authenticate.isAllow([ROLES.Manager, ROLES.Operation]) ? "inherit" : "none";
        return (
            <Layout style={{ height: window.innerHeight, minHeight: '100vh', }}>

                <Sider
                    trigger={null}
                    theme='dark'
                    collapsible
                    collapsed={this.state.collapsed}
                    onCollapse={this.onCollapse}>
                    <div className="logo" >
                        <img src="logo.svg" style={{ maxWidth: "100%", background: "#fff", padding: "2px", borderRadius: "2px" }} />
                    </div>
                    <Menu theme='dark'
                        // defaultSelectedKeys={['1']} 
                        mode="inline">
                        {routerLinks}
                        <style>
                            {`
                                #disk-usage-progress .ant-progress-text {
                                    display: none;
                                }
                                `}
                        </style>
                        <li style={{ paddingLeft: "25px" }} id='disk-usage-progress'>
                            <Progress strokeColor={{
                                '0%': '#108ee9',
                                '100%': '#E91E63',
                            }}
                                percent={percentUsage} />
                        </li>
                        <li style={{ padding: "0px 25px" }}>
                            <p style={{ lineHeight: "20px", fontSize: 12 }}><Icon style={{ fontSize: "18px" }} type="cloud" /> Đã sử dụng {diskUsageValueGb}GB trên {totalDiskUsageGb.replace("GB", "")}GB bộ nhớ được cấp, dung lượng hệ thống còn lại {this.state.severStorageInfo.systemDiskAvailable}GB.
                                <a href={`${domainName}/system-info/campaign-disk-usage`} target='_blank'> Tải dung lượng chi tiết</a>
                            </p>
                        </li>
                    </Menu>
                </Sider>
                <Layout>
                    <Header style={{ background: '#fff', padding: 0, paddingLeft: 16 }}>
                        <Icon
                            className="trigger"
                            type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                            style={{ cursor: 'pointer', position: "relative", zIndex: 99 }}
                            onClick={this.toggle}
                        />

                        <Menu className="user-menu" selectedKeys={[this.state.current]} mode="horizontal" >
                            <SubMenu
                                title={
                                    <span className=""> <Icon type="user" /> {this.state.userName} </span>
                                }
                            >
                                <Menu.ItemGroup key="g2" title="">
                                    <Menu.Item style={{ display: showChangePass }} onClick={() => { this.setState({ openChangePassModel: true }) }} key="3">Đổi mật khẩu</Menu.Item>
                                    <Menu.Item onClick={this.logout} key="4">Đăng xuất</Menu.Item>
                                </Menu.ItemGroup>
                            </SubMenu>
                        </Menu>
                        {/* <SubMenu
              title={
                <span className="submenu-title-wrapper">
                  <Icon type="setting" />
                  Navigation Three - Submenu
            </span>
              }
            >
              
            </SubMenu> */}
                    </Header>
                    <Content style={{ minHeight: '100vh - 300px' }} >
                        {switchRoutes}
                    </Content>
                    <ChangePassForm
                        onClose={() => { this.setState({ openChangePassModel: false }) }}
                        visible={this.state.openChangePassModel}
                    />
                    <DiskUsageWarningModal
                        visible={this.state.openDiskUsageWarningModal}
                        closeModal={() => { this.setState({ openDiskUsageWarningModal: false }) }}
                        serviceDiskAvailable={this.state.severStorageInfo.systemDiskAvailable}
                    />
                    <Footer style={{ display: "none", textAlign: 'center' }}>Copyright©{new Date().getFullYear()} AEON</Footer>
                </Layout>
            </Layout>
        );
    }
}
