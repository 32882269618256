import { Button, Card, Form, Input, Modal, Select, Icon } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React from 'react';
import { toast } from 'react-toastify';
import userService from '~/services/user/user.service';
import { HttpStatus } from '~/common/enums/const';
import Authenticate from '~/services/authenticate/authenticate';
import history from '~/common/utils/browser-history';


export interface IFormModalProps extends FormComponentProps {
    visible: boolean;
    serviceDiskAvailable: number;
    closeModal: () => void;
}

export const DiskUsageWarningModal = (props: {visible: boolean, serviceDiskAvailable: number, closeModal: () => void}) => {
    let { visible, serviceDiskAvailable, closeModal } = props;

    const handleClose = () => {
        // Logic to hide the modal
        visible = false;
    };

    return (
        <Modal bodyStyle={{ padding: "5px" }} width="400px" footer={null} closable={false} visible={visible}>
            <Card title="CẢNH BÁO DUNG LƯỢNG THẤP" bordered={true}>
                <h4>Dung lượng còn lại : {serviceDiskAvailable} GB</h4>
                <p>Dung lượng còn có thể sử dụng còn dưới 10GB, vui lòng backup và xoá bớt dữ liệu chương trình.</p>
                <Button type="primary" onClick={closeModal}>Close</Button>
            </Card>
        </Modal>
    );
}

// const ChangePassForm = Form.create<IFormModalProps>({
//     name: "form"
// })(DiskUsageWarningModal);
// export default ChangePassForm;